export type SizeMapTypes =
  | "xxSmall"
  | "xSmall"
  | "small"
  | "medium"
  | "large"
  | "xLarge";

export const sizeMap = {
  xxSmall: "w-3 h-3",
  xSmall: "w-4 h-4",
  small: "w-5 h-5",
  medium: "w-6 h-6",
  large: "w-7 h-7",
  xLarge: "w-8 h-8",
};
