import React, { useState, useContext } from "react";
import { useTransition, useSpring } from "react-spring";
import Close from "assets/icons/Close.svg";
import { springConfigs } from "styles";
import Confirmation from "../Confirmation";
import * as S from "./styles";

interface ConfirmationProps {
  title: string;
  description: string;
  confirmationText?: string;
  content?: React.ReactNode;
  buttonText?: string;
  confirmationType?: "default" | "danger";
  onConfirmation: () => void;
}

interface DefaultContext {
  confirm: (confirmation: ConfirmationProps) => void;
}

const defaultContext: DefaultContext = {
  confirm: undefined,
};

const ConfirmationContext = React.createContext(defaultContext);

const useConfirmationContext = () => {
  return useContext(ConfirmationContext);
};

const ConfirmationContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [confirmation, setConfirmation] = useState<ConfirmationProps>();

  const handleClose = () => {
    setConfirmation(null);
  };

  const confirm = (t: ConfirmationProps) => {
    handleClose();
    setTimeout(() => {
      setConfirmation(t);
    });
  };

  const transitions = useTransition(confirmation, null, {
    config: springConfigs.highTension,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0, pointerEvents: "none" },
  });

  const animateModal = useSpring(
    confirmation
      ? {
          config: springConfigs.highTension,
          transform: "scale(1, 1)",
        }
      : {
          config: springConfigs.highTension,
          transform: "scale(0.95, 0.95)",
        }
  );

  const renderConfirmation = () => {
    return transitions.map(
      ({ item, key, props }) =>
        item && (
          <S.ConfirmationContainer key={key} style={props}>
            <S.ConfirmationModal style={animateModal}>
              <Confirmation
                title={item.title}
                description={item.description}
                confirmationText={item.confirmationText}
                buttonText={item.buttonText}
                confirmationType={item.confirmationType}
                onConfirmation={() => {
                  handleClose();
                  item.onConfirmation();
                }}
              >
                {item.content}
              </Confirmation>
              <S.CloseButton
                buttonStyle="icon"
                onClick={handleClose}
                rightIcon={<Close />}
              />
            </S.ConfirmationModal>
            <S.Mask onClick={handleClose} />
          </S.ConfirmationContainer>
        )
    );
  };

  return (
    <ConfirmationContext.Provider value={{ confirm }}>
      {renderConfirmation()}
      {children}
    </ConfirmationContext.Provider>
  );
};

export { useConfirmationContext, ConfirmationContextProvider };
