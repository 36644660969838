import styled, { css, keyframes } from "styled-components";
import { animated } from "react-spring";

const loader = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

interface SpinnerProps {
  $size: "small" | "medium" | "large";
  $spinnerColor: string;
}

const getSize = (size: SpinnerProps["$size"]) => {
  if (size === "small") return "15px";
  if (size === "large") return "60px";

  return "30px";
};

export const Loader = styled.div<{ isFullScreen: boolean; fillSpace: boolean }>`
  ${({ theme: { colors }, isFullScreen, fillSpace }) => {
    if (isFullScreen)
      return css`
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: ${colors.grey1};
        display: flex;
        align-items: center;
        justify-content: center;
      `;

    if (fillSpace)
      return css`
        flex: 1;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      `;

    return "";
  }}
`;

export const SpinnerContainer = styled(animated.div)<SpinnerProps>`
  ${({ theme: { colors }, $size, $spinnerColor }) => css`
    height: ${getSize($size)};
    width: ${getSize($size)};
    animation: ${loader} 1.4s infinite linear;
    transform-origin: center center;
    color: ${$spinnerColor || colors.grey4};
  `}
`;
