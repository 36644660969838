import tw, { styled, css } from "twin.macro";
import ContentCopyIcon from "assets/icons/ContentCopy.svg";
import { colors as c, variables as v } from "styles";

export const SizeMap = {
  medium: tw`h-4 w-4`,
  large: tw`h-5 w-5`,
};

export const Icon = styled(ContentCopyIcon)<{
  $colorOverride: keyof typeof c;
  size: keyof typeof SizeMap;
}>(() => [
  ({ size }) => SizeMap[size],
  ({ $colorOverride }) =>
    $colorOverride &&
    css`
      fill: ${c[$colorOverride]};
    `,
]);

export const IconContainer = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const IconFlyoutContainer = styled.div`
  position: absolute;
  width: auto;
  top: 0px;
  margin-top: 25px;

  opacity: ${({ copied, hovering }: { copied: boolean; hovering: boolean }) =>
    copied || hovering ? 1 : 0};

  &:hover {
    transition: ${v.mainTransition} ease;
    opacity: 1;
  }
`;

export const IconFlyout = styled.div`
  background-color: ${c.black};
  padding: ${1 * v.gridUnit}px ${2 * v.gridUnit}px;
  color: ${c.white};
  border-radius: ${v.mainBorderRadius};
  font-size: ${v.fontSize5};
`;
