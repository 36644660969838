import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

const LazyPlanPricesList = React.lazy(
  () => import("containers/Plans/new/PlanPrices/PlanPricesList")
);
const LazyPlanPrice = React.lazy(
  () => import("containers/Plans/new/PlanPrices/Price")
);

export const PlanPricesRoutes = () => {
  const { path } = useRouteMatch();

  const pages = [
    {
      exact: true,
      path: `${path}`,
      Component: LazyPlanPricesList,
    },
    {
      exact: true,
      path: `${path}/create`,
      Component: LazyPlanPrice,
    },
    {
      exact: true,
      path: `${path}/:priceId`,
      Component: LazyPlanPrice,
    },
  ];

  return (
    <Switch>
      {pages.map(({ exact, path: routePath, Component }) => (
        <Route key={path} exact={exact} path={routePath}>
          <Component />
        </Route>
      ))}
    </Switch>
  );
};
