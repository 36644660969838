import styled, { css } from "styled-components";
import B from "components/Button";
import { animated } from "react-spring";

export const ConfirmationContainer = styled(animated.div)`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    border-radius: ${theme.variables.mainBorderRadius};
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const ConfirmationModal = styled(animated.aside)`
  ${({ theme: { colors, variables } }) => css`
    width: 400px;
    background-color: ${colors.white};
    z-index: 1;
    padding: ${variables.gutter};
    border-radius: ${variables.mainBorderRadius};
    overflow: hidden;
    position: relative;
    min-height: 150px;
    display: flex;
  `}
`;

export const Mask = styled.button`
  background-color: ${({ theme }) => theme.colors.black};
  opacity: 0.7;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
`;

export const CloseButton = styled(B)`
  ${({ theme: { variables } }) => css`
    position: absolute;
    top: ${variables.gutter};
    right: ${variables.gutter};
  `}
`;
