import { useState, useEffect, useMemo } from "react";
import useContextHelpers from "hooks/useContextHelpers";
import { QueryResult } from "@apollo/client";
// eslint-disable-next-line import/no-unresolved
import isEqual from "lodash.isequal";

const useAppState = <Query>(queryResponse: QueryResult) => {
  const [workingState, setWorkingState] = useState<Query>();
  const [isReady, setIsReady] = useState(false);
  const { data, loading, error, refetch } = queryResponse;
  const { updateNested, getNested } = useContextHelpers();
  /**
   * Used to update a PricePaymentOption in the local Apollo cache.
   */
  const updateInCache = (path: (string | number)[], value: unknown) => {
    const updatedWorkingState = updateNested(
      workingState,
      path,
      value
    ) as unknown as Query;

    setWorkingState(updatedWorkingState);
  };

  const undoChanges = (path: (string | number)[]) => {
    const nestedData = getNested(data, path);
    const updatedWorkingState = updateNested(
      workingState,
      path,
      nestedData
    ) as unknown as Query;
    setWorkingState(updatedWorkingState);
  };

  const checkForStateDifferences = (obj1: unknown, obj2: unknown) => {
    return !isEqual(obj1, obj2);
  };

  const checkIfUpdated = (path: (string | number)[]) => {
    const nestedWorkingState = getNested(workingState, path);
    const nestedData = getNested(data, path);

    return checkForStateDifferences(nestedWorkingState, nestedData);
  };

  const useCheckIfUpdated = (path: (string | number)[]) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => checkIfUpdated(path), [path, data, workingState]);
  };

  useEffect(() => {
    if (data && !loading) {
      if (!error) {
        setWorkingState(data);
      }
      setIsReady(true);
    }
  }, [data, loading, error]);

  return {
    error,
    refetch,
    undoChanges,
    workingState,
    updateInCache,
    useCheckIfUpdated,
    isLoading: !isReady,
    persistedState: data,
  };
};

export default useAppState;
