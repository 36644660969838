import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

const LazyPlanHome = React.lazy(() => import("containers/Plans/new/PlansHome"));
const LazyPlanCreate = React.lazy(
  () => import("containers/Plans/new/PlanCreate")
);
const LazyPlan = React.lazy(() => import("containers/Plans/new/Plan"));
const LazyPlanInfo = React.lazy(
  () => import("containers/Plans/new/PlanInformation")
);
const LazyPlanPermissions = React.lazy(
  () => import("containers/Plans/new/PlanPermissions")
);
const LazyPlanContentGroups = React.lazy(
  () => import("containers/Plans/new/PlanContentGroups")
);
const LazyPlanRedirects = React.lazy(
  () => import("containers/Plans/new/PlanRedirects")
);
const LazyPlanPrices = React.lazy(
  () => import("containers/Plans/new/PlanPrices")
);
const LazyGlobalSettings = React.lazy(
  () => import("containers/Plans/new/GlobalSettings")
);
const LazyGlobalPermissions = React.lazy(
  () => import("containers/Plans/new/GlobalSettingsPermissions")
);
const LazyGlobalContentGroups = React.lazy(
  () => import("containers/Plans/new/GlobalSettingsContentGroups")
);
const LazyGlobalRedirects = React.lazy(
  () => import("containers/Plans/new/GlobalRedirects")
);

export const PlansRoutes = () => {
  const { path } = useRouteMatch();

  const pages = [
    {
      exact: true,
      path: `${path}`,
      Component: LazyPlanHome,
    },
    {
      exact: true,
      path: `${path}/create`,
      Component: LazyPlanCreate,
    },
    { exact: true, path: `${path}/settings`, Component: LazyGlobalSettings },
    {
      exact: true,
      path: `${path}/settings/permissions`,
      Component: LazyGlobalPermissions,
    },
    {
      exact: true,
      path: `${path}/settings/content-groups`,
      Component: LazyGlobalContentGroups,
    },
    {
      exact: true,
      path: `${path}/settings/redirects`,
      Component: LazyGlobalRedirects,
    },
    {
      exact: true,
      path: `${path}/:planId/information`,
      Component: LazyPlanInfo,
    },
    {
      exact: true,
      path: `${path}/:planId/permissions`,
      Component: LazyPlanPermissions,
    },
    {
      exact: true,
      path: `${path}/:planId/content-groups`,
      Component: LazyPlanContentGroups,
    },
    {
      exact: true,
      path: `${path}/:planId/redirects`,
      Component: LazyPlanRedirects,
    },
    {
      exact: false,
      path: `${path}/:planId/prices`,
      Component: LazyPlanPrices,
    },
    {
      exact: false,
      path: `${path}/:planId`,
      Component: LazyPlan,
    },
  ];

  return (
    <Switch>
      {pages.map(({ exact, path: routePath, Component }) => (
        <Route key={path} exact={exact} path={routePath}>
          <Component />
        </Route>
      ))}
    </Switch>
  );
};
