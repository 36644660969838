import { useState } from "react";

export const useLocalStorage = (key: string, initialValue?: object | string) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue || "";
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: object | string) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);
    window.localStorage.setItem(key, typeof valueToStore === "string" ? valueToStore : JSON.stringify(valueToStore));
  };

  return [storedValue, setValue];
}