import React from "react";
import { IconProps } from "./Icons.type";

const Visa: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="31"
      height="10"
      viewBox="0 0 31 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Visa</title>
      <path
        d="M11.788 0.150755L7.72492 9.84924H5.06634L3.05987 2.11055C2.95955 1.65829 2.80906 1.45729 2.45793 1.25628C1.85599 0.954774 0.852751 0.603016 0 0.452262L0.050161 0.150755H4.31392C4.8657 0.150755 5.36731 0.502514 5.46764 1.15578L6.52104 6.78392L9.12945 0.201006H11.788V0.150755ZM22.1715 6.68342C22.1715 4.1206 18.6602 3.96985 18.6602 2.86432C18.6602 2.51256 19.0113 2.1608 19.7136 2.0603C20.0647 2.01005 21.068 1.9598 22.1715 2.51256L22.623 0.452262C22.021 0.251257 21.2686 0 20.2654 0C17.8074 0 16.0518 1.30653 16.0518 3.21608C16.0518 4.62312 17.3058 5.37688 18.2589 5.82914C19.212 6.28141 19.5631 6.58291 19.5631 7.03518C19.5631 7.68844 18.8107 7.98995 18.0583 7.98995C16.8042 7.98995 16.0518 7.63819 15.5 7.38694L15.0485 9.49749C15.6505 9.74874 16.7039 10 17.8074 10C20.4159 10 22.1214 8.69347 22.1715 6.68342ZM28.6926 9.84924H31L28.9935 0.150755H26.8366C26.3349 0.150755 25.9337 0.452264 25.7832 0.854274L22.021 9.84924H24.6294L25.1311 8.39196H28.3414L28.6926 9.84924ZM25.8835 6.43216L27.1877 2.81407L27.9401 6.43216H25.8835ZM15.3495 0.150755L13.2929 9.84924H10.7848L12.8414 0.150755H15.3495Z"
        fill="#1A1F71"
      />
    </svg>
  );
};

export default Visa;
