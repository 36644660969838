import React from "react";
import useAppState from "hooks/useAppState";
// eslint-disable-next-line import/no-unresolved
import { ApolloError } from "@apollo/client";
import { useCurrentUserQuery, CurrentUserQuery } from "../queries.generated";

interface DefaultContext {
  workingState: CurrentUserQuery;
  error: ApolloError;
  isLoading: boolean;
  checkIfUserUpdated: () => boolean;
  updateCache: (keys: string[], value: unknown) => void;
}

const defaultContext: DefaultContext = {
  isLoading: false,
  checkIfUserUpdated: undefined,
  error: undefined,
  workingState: undefined,
  updateCache: undefined,
};

const UsersContext = React.createContext(defaultContext);

const useUsersContext = () => {
  return React.useContext(UsersContext);
};

const UsersContextProvider = ({ children }: { children: React.ReactNode }) => {
  const queryResponse = useCurrentUserQuery({});
  const {
    error,
    isLoading,
    useCheckIfUpdated,
    updateInCache: updateCache,
    workingState,
  } = useAppState<CurrentUserQuery>(queryResponse);

  return (
    <UsersContext.Provider
      value={{
        error,
        isLoading,
        // eslint-disable-next-line react-hooks/rules-of-hooks
        checkIfUserUpdated: () => useCheckIfUpdated([]),
        updateCache,
        workingState,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export { useUsersContext, UsersContextProvider };
