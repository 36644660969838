import React, { useState, useEffect } from "react";
import { useTransition } from "react-spring";
import L from "assets/icons/Loader.svg";
import * as S from "./styles";

interface LoaderProps {
  size?: "small" | "medium" | "large";
  spinnerColor?: string;
  isFullScreen?: boolean;
  fillSpace?: boolean;
  preventDelay?: boolean;
}

const Loader = ({
  size,
  isFullScreen,
  spinnerColor,
  fillSpace,
  preventDelay,
}: LoaderProps) => {
  const [showLoader, setShowLoader] = useState(preventDelay);

  useEffect(() => {
    if (!preventDelay) {
      setTimeout(() => setShowLoader(true), 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transitions = useTransition(showLoader, null, {
    config: { duration: 150 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  if (!showLoader) {
    return null;
  }

  return (
    <S.Loader isFullScreen={isFullScreen} fillSpace={fillSpace}>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <S.SpinnerContainer
              key={key}
              style={props}
              $size={size}
              $spinnerColor={spinnerColor}
            >
              <L />
            </S.SpinnerContainer>
          )
      )}
    </S.Loader>
  );
};

Loader.defaultProps = {
  size: "medium",
  spinnerColor: undefined,
  isFullScreen: false,
  fillSpace: false,
  preventDelay: false,
};

export default Loader;
