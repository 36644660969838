import React from "react";
import Spacer from "components/Spacer";
import Error from "assets/icons/Error.svg";
import Warning from "assets/icons/Warning.svg";
import CheckSquare from "assets/icons/CheckSquare.svg";
import CloseSquareFill from "assets/icons/CloseSquareFill.svg";
import * as S from "./styles";

interface ToastAlertProps {
  message?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  alertType?: "success" | "error" | "warning";
  timeout: number;
  onClose: () => void;
}

const ToastAlert = ({
  message,
  timeout,
  alertType,
  onClose,
}: ToastAlertProps) => {
  const titles = {
    success: "Success!",
    error: "Uh oh!",
    warning: "Watch out!",
  };

  const Icon = {
    success: CheckSquare,
    error: Error,
    warning: Warning,
  }[alertType];

  return (
    <S.ToastAlertContainer>
      <S.ToastAlert>
        <S.Heading type="h4" alertType={alertType}>
          <Icon style={{ height: 20, width: 20 }} />
          <Spacer isVertical={false} spacing="small" />
          {titles[alertType]}
        </S.Heading>
        {message && <S.Text alertType={alertType}>{message}</S.Text>}
        <S.TimeoutBar timeout={timeout} alertType={alertType} />
      </S.ToastAlert>
      <S.CloseButton
        rightIcon={<CloseSquareFill />}
        buttonStyle="icon"
        onClick={onClose}
        alignSelf="start"
        colorOverride="grey4"
      />
    </S.ToastAlertContainer>
  );
};

ToastAlert.defaultProps = {
  alertType: "error",
  message: undefined,
};

export default ToastAlert;
