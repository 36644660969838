import React from "react";
import { render } from "react-dom";
import { getApolloContext } from "@apollo/client";
import { getClient } from "apolloClient";
import App from "containers/App";
import { EnvContextProvider, useEnvContext } from "containers/Environment";
import * as Sentry from "@sentry/react";
import "./styles.css";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: "https://3c74adeedba147bb8148ba13021a7267@o220641.ingest.sentry.io/1427842",
  });
}

const AppWithApollo = () => {
  const ApolloContext = getApolloContext();
  const { env } = useEnvContext();
  const [client, setClient] = React.useState(null);

  React.useEffect(() => {
    getClient(env).then((apolloClient) => setClient(apolloClient));
  }, [env]);

  if (!client) return null;

  return (
    <ApolloContext.Provider value={{ client }}>
      <App />
    </ApolloContext.Provider>
  );
};

render(
  <EnvContextProvider>
    <AppWithApollo />
  </EnvContextProvider>,
  document.getElementById("container")
);
