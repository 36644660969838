import React from "react";

let lastId = 0;

const useGenerateComponentID = () => {
  const [id] = React.useState((lastId += 1));
  return `id-${id}`;
};

export default useGenerateComponentID;
