import React from "react";
import * as S from "./styles";

export interface SpacerProps {
  spacing?:
    | "none"
    | "icon"
    | "xxSmall"
    | "xSmall"
    | "small"
    | "regular"
    | "medium"
    | "large"
    | "xLarge"
    | "auto";
  isVertical?: boolean;
}

const Spacer = ({ spacing, isVertical }: SpacerProps) => {
  const spacingSizes = {
    none: "0px",
    icon: "2px",
    xxSmall: "4px",
    xSmall: "8px",
    small: "12px",
    regular: "16px",
    medium: "24px",
    large: "32px",
    xLarge: "64px",
    auto: "auto",
  };

  return (
    <S.Spacer spacingSize={spacingSizes[spacing]} isVertical={isVertical} />
  );
};

Spacer.defaultProps = {
  spacing: "regular",
  isVertical: true,
};

export default Spacer;
