import Text from "components/Text";
import Input from "components/Input";
import Button from "components/Button";
import Spacer from "components/Spacer";
import Heading from "components/Heading";
import React, { useState } from "react";
import { colors } from "styles/theme/colors";
import * as S from "./styles";

interface ConfirmationProps {
  title: string;
  description: string;
  buttonText?: string;
  children?: React.ReactNode;
  confirmationType?: "default" | "danger";
  confirmationText?: string;
  onConfirmation: () => void;
}

const Confirmation = ({
  title,
  children,
  buttonText,
  description,
  confirmationType,
  confirmationText,
  onConfirmation,
}: ConfirmationProps) => {
  const [confirmationTextVal, setConfirmationTextVal] = useState("");
  const getButtonColor = () => {
    const colorMap: {
      [key in ConfirmationProps["confirmationType"]]: keyof typeof colors;
    } = {
      default: "blue1",
      danger: "red2",
    };

    return colorMap[confirmationType];
  };

  const renderTextConfirmation = () => {
    return (
      <>
        <Text>
          Please type <strong>{confirmationText}</strong> to proceed.
        </Text>
        <Input
          value={confirmationTextVal}
          onChange={(e) => setConfirmationTextVal(e.target.value)}
          placeholder={confirmationText}
        />
        <Spacer spacing="xSmall" />
        <Button
          text={buttonText}
          backgroundColorOverride={getButtonColor()}
          isDisabled={confirmationText !== confirmationTextVal}
          onClick={onConfirmation}
        />
      </>
    );
  };

  const renderButtonConfirmation = () => {
    return (
      <Button
        text={buttonText}
        onClick={onConfirmation}
        backgroundColorOverride={getButtonColor()}
      />
    );
  };

  return (
    <S.Confirmation>
      <Heading type="h3">{title}</Heading>
      <Text noMargin>{description}</Text>
      {children && (
        <>
          <Spacer />
          {children}
        </>
      )}
      <Spacer />
      <Spacer spacing="auto" />
      {confirmationText ? renderTextConfirmation() : renderButtonConfirmation()}
    </S.Confirmation>
  );
};

Confirmation.defaultProps = {
  children: undefined,
  buttonText: "Confirm",
  confirmationType: "default",
  confirmationText: undefined,
};

export default Confirmation;
