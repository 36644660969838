import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import useAuth from "hooks/useAuth";
import { createPath, RouteName } from "enums/routes";
import Loader from "components/Loader";

interface AuthWrapperProps {
  children: React.ReactNode;
  isAuth?: boolean;
}

export const AuthWrapper = ({ children, isAuth }: AuthWrapperProps) => {
  const {
    auth: { refetch, currentUser, loading, isLoggedIn },
  } = useAuth();

  useEffect(() => {
    refetch();
  }, [currentUser]);

  if (loading) return <Loader isFullScreen />;
  if (isAuth && !isLoggedIn)
    return <Redirect to={createPath(RouteName.login)} />;

  return <>{children}</>;
};

AuthWrapper.defaultProps = {
  isAuth: false,
};
