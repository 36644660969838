import React from "react";
import { StyledText, TextProps } from "./styles";

const Text = ({
  type,
  bold,
  italic,
  underline,
  children,
  noMargin,
  className,
  color,
  ellipsisOverflow,
  textAlign,
}: TextProps) => {
  return (
    <StyledText
      type={type}
      color={color}
      bold={bold}
      italic={italic}
      underline={underline}
      noMargin={noMargin}
      className={className}
      textAlign={textAlign}
      ellipsisOverflow={ellipsisOverflow}
    >
      {children}
    </StyledText>
  );
};

Text.defaultProps = {
  type: "regular",
  bold: false,
  italic: false,
  underline: false,
  noMargin: false,
  textAlign: "left",
  ellipsisOverflow: false,
};

export default Text;
