import styled, { css } from "styled-components";
import Tippy from "@tippyjs/react";

export const Tooltip = styled(Tippy)<{ placement: string }>`
  background-color: ${(props) => props.theme.colors?.grey800};
  border: none;
  padding: 4px 8px;
  font-size: 16px;
  line-height: 28px;
  border-radius: 4px;
  color: white;
  & > .tippy-svg-arrow > #arrow {
    content: " ";
    position: absolute;
    border-style: solid;
    border-width: 5px;
    ${(props) =>
      props.placement === "top" &&
      css`
        top: 100%; /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-color: transparent;
        border-top-color: ${props.theme.colors?.grey800}; ;
      `}

    ${(props) =>
      props.placement === "bottom" &&
      css`
        bottom: 100%; /* At the top of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-color: transparent;
        border-bottom-color: ${props.theme.colors?.grey800};
      `}

      ${(props) =>
      props.placement === "right" &&
      css`
        top: 50%;
        right: 100%; /* To the left of the tooltip */
        margin-top: -5px;
        border-color: transparent;
        border-right-color: ${props.theme.colors?.grey800};
      `}

      ${(props) =>
      props.placement === "left" &&
      css`
        top: 50%;
        left: 100%; /* To the right of the tooltip */
        margin-top: -5px;
        border-color: transparent;
        border-left-color: ${props.theme.colors?.grey800};
      `}
  }
`;

export const Container = styled.div`
  display: table;
`;
