import Text from "components/Text";
import { InfoTooltip } from "components/Tooltip";
import React from "react";
import * as S from "./styles";

export type HeaderTypes = "h1" | "h2" | "h3" | "h4";
export interface HeadingProps {
  type?: HeaderTypes;
  toolTip?: string;
  className?: string;
  children: React.ReactNode;
  isCentered?: boolean;
  bold?: boolean;
}

const Heading = ({
  type,
  children,
  toolTip,
  className,
  isCentered,
  bold,
}: HeadingProps) => {
  const StyledHeading = {
    h1: S.Heading1,
    h2: S.Heading2,
    h3: S.Heading3,
    h4: S.Heading4,
  }[type];

  return (
    <StyledHeading className={className} isCentered={isCentered} bold={bold}>
      {children}
      {toolTip && (
        <>
          <div css="margin-left: 4px;">
            <Text type="small" noMargin>
              <InfoTooltip content={toolTip} />
            </Text>
          </div>
        </>
      )}
    </StyledHeading>
  );
};

Heading.defaultProps = {
  type: "h1",
  isCentered: false,
  bold: true,
};

export default Heading;
