import { createGlobalStyle } from "styled-components";
import PlusJakartaTextBold from "assets/fonts/PlusJakartaText-Bold.ttf";
import PlusJakartaTextRegular from "assets/fonts/PlusJakartaText-Regular.ttf";
import { colors as c } from "./theme/colors";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Plus Jakarta Text";
    font-style: normal;
    font-weight: bold;
    src: url(${PlusJakartaTextBold}) format("truetype");
  }

  @font-face {
    font-family: "Plus Jakarta Text";
    font-style: normal;
    font-weight: normal;
    src: url(${PlusJakartaTextRegular}) format("truetype");
  }

  html, button, input {
    font-family: "Plus Jakarta Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 13px;
  }

  body {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    line-height: 1.55;
    background-color: ${c.grey1};
  }
`;
