import { gql } from "@apollo/client";

export const GET_USER = gql`
  query CurrentUser {
    currentUser {
      id
      profile {
        firstName
        lastName
      }
      auth {
        email
      }
      appConnections {
        role
        app {
          id
          name
          status
        }
      }
    }
  }
`;
