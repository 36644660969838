import { ApolloClient, ApolloLink, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import useAuth from "hooks/useAuth";
import typeDefs from "./typeDefs";
import { cache } from "./cache";

export interface Env {
  isSandbox: boolean;
  isLive: boolean;
}

const getURI = (env: Env) => {
  return `${process.env.API_ENDPOINT}?mode=${
    env.isSandbox ? "sandbox" : "live"
  }`;
};

const getAppId = () => {
  const pathParts = window.location.pathname.split("/");
  const appIndex = pathParts.indexOf("apps");

  if (!pathParts.includes("apps")) {
    // This is used for the Stripe connection page.
    // The app id is available on the redirect URI state (?state=app_....)
    return new URLSearchParams(window.location.search).get("state");
  }

  return pathParts[appIndex + 1];
};

const httpLink = (env: Env) =>
  new HttpLink({
    uri: getURI(env),
    credentials: "include",
  });

const logoutLink = onError(({ networkError }) => {
  const { logout } = useAuth();

  if (
    networkError &&
    "statusCode" in networkError &&
    networkError.statusCode === 401
  ) {
    logout();
  }
});

export const clientProps = {
  connectToDevTools: true,
  typeDefs,
  resolvers: {
    Price: {
      // Set defaults for local-only fields
      isPersisted: () => true,
    },
    Plan: {
      isPersisted: () => true,
    },
  },
};

export const createHttpLink = (env: Env) =>
  ApolloLink.from([
    new ApolloLink((operation, forward) => {
      const appId = getAppId();

      operation.setContext({
        headers: {
          ...(appId && { "ms-app-id": getAppId() }),
          "ms-mode": env.isSandbox ? "sandbox" : "live",
        },
      });

      return forward(operation);
    }),
    httpLink(env),
    logoutLink,
  ]);

// This is needed to remove "__typename" when making mutations.
// More info: https://github.com/apollographql/apollo-feature-requests/issues/6
// https://github.com/apollographql/apollo-feature-requests/issues/6
const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: unknown) =>
      key === "__typename" ? undefined : value;
    // eslint-disable-next-line no-param-reassign
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

export const getClient = async (env: {
  isSandbox: boolean;
  isLive: boolean;
}) => {
  return new ApolloClient({
    cache,
    link: ApolloLink.from([cleanTypeName, createHttpLink(env)]),
    ...clientProps,
  });
};
