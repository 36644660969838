import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

const LazyPaymentSettings = React.lazy(
  () => import("containers/Settings/PaymentSettings")
);
const LazySettingsDangerZone = React.lazy(
  () => import("containers/Settings/DangerZone")
);

const LazyAppSettings = React.lazy(
  () => import("containers/Settings/ApplicationSettings")
);

export const SettingsRoutes = () => {
  const { path, url } = useRouteMatch();

  const pages = [
    {
      exact: true,
      path: `${path}/`,
      // eslint-disable-next-line react/display-name
      Component: () => <Redirect to={`${url}/application`} />,
    },
    {
      exact: true,
      path: `${path}/application`,
      Component: LazyAppSettings,
    },
    {
      exact: true,
      path: `${path}/paymentSettings`,
      Component: LazyPaymentSettings,
    },
    {
      exact: true,
      path: `${path}/dangerZone`,
      Component: LazySettingsDangerZone,
    },
  ];

  return (
    <Switch>
      {pages.map(({ exact, path: routePath, Component }) => (
        <Route key={path} exact={exact} path={routePath}>
          <Component />
        </Route>
      ))}
    </Switch>
  );
};
