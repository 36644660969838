import React from "react";
import Loader from "components/Loader";
import Spacer from "components/Spacer";
import { colors } from "styles/theme/colors";
import * as S from "./styles";

interface ButtonProps {
  text?: string;
  type?: "button" | "submit" | "reset";
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  buttonStyle?: "default" | "transparent" | "skeleton" | "highlight" | "icon";
  isDisabled?: boolean;
  isLoading?: boolean;
  noBorder?: boolean;
  noOutline?: boolean;
  alignSelf?: "start" | "center" | "end" | "initial";
  colorOverride?: string;
  backgroundColorOverride?: string;
  className?: string;
  noPadding?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      text,
      type,
      leftIcon,
      rightIcon,
      onClick,
      buttonStyle,
      isDisabled,
      isLoading,
      noBorder,
      noOutline,
      alignSelf,
      colorOverride,
      backgroundColorOverride,
      className,
      noPadding,
    }: ButtonProps,
    ref
  ) => {
    const handleClick = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (isDisabled) return;
        if (!onClick) return;

        onClick(e);
      },
      [onClick, isDisabled]
    );

    return (
      <S.Button
        ref={ref}
        type={type}
        onClick={handleClick}
        variant={buttonStyle}
        disabled={isDisabled || isLoading}
        noBorder={noBorder}
        noOutline={noOutline}
        alignSelf={alignSelf}
        colorOverride={colorOverride}
        backgroundColorOverride={backgroundColorOverride}
        className={className}
        noPadding={noPadding}
      >
        {leftIcon}
        {leftIcon && text && <Spacer isVertical={false} spacing="icon" />}
        {text}
        {rightIcon && (text || leftIcon) && (
          <Spacer isVertical={false} spacing="icon" />
        )}
        {rightIcon}
        {isLoading && (
          <S.LoaderContainer
            variant={buttonStyle}
            backgroundColorOverride={backgroundColorOverride}
          >
            <Loader
              size="small"
              spinnerColor={
                buttonStyle === "skeleton" ? colors.black : colors.white
              }
              preventDelay
            />
          </S.LoaderContainer>
        )}
      </S.Button>
    );
  }
);

Button.defaultProps = {
  text: "",
  leftIcon: undefined,
  rightIcon: undefined,
  type: "button",
  onClick: null,
  noBorder: false,
  noOutline: false,
  isDisabled: false,
  isLoading: false,
  className: undefined,
  alignSelf: "initial",
  buttonStyle: "default",
  colorOverride: null,
  backgroundColorOverride: undefined,
  noPadding: false,
};

Button.displayName = "Button";

export default Button;
