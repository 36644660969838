import React from "react";
import { IconProps } from "./Icons.type";

const Facebook: React.FC<IconProps> = ({ className, fill = "#000000" }) => (
  <svg
    className={className}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    width="1365.331"
    height="1365.331"
    fillRule="evenodd"
    clipRule="evenodd"
    imageRendering="optimizeQuality"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    viewBox="0 0 14222 14222"
  >
    <g>
      <path
        d="M14222 7111C14222 3184 11038 0 7111 0S0 3184 0 7111c0 3549 2600 6491 6000 7025V9167H4194V7111h1806V5544c0-1782 1062-2767 2686-2767 778 0 1592 139 1592 139v1750h-897c-883 0-1159 548-1159 1111v1334h1972l-315 2056H8222v4969c3400-533 6000-3475 6000-7025z"
        className="fil0"
        fill="#1977f3"
        fillRule="nonzero"
      />
      <path
        d="M9879 9167l315-2056H8222V5777c0-562 275-1111 1159-1111h897V2916s-814-139-1592-139c-1624 0-2686 984-2686 2767v1567H4194v2056h1806v4969c362 57 733 86 1111 86s749-30 1111-86V9167h1657z"
        className="fil1"
        fill="#fefefe"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default Facebook;
