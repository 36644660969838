import { gql } from "@apollo/client";

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      id
      auth {
        email
      }
      profile {
        firstName
        lastName
      }
    }
  }
`;

export const RESET_USER_PASSWORD = gql`
  mutation ResetUserPassword($input: UserResetPasswordInput!) {
    resetUserPassword(input: $input) {
      id
    }
  }
`;

export const SEND_USER_RESET_PASSWORD_EMAIL = gql`
  mutation SendUserResetPasswordEmail($input: UserResetPasswordEmailInput!) {
    sendUserResetPasswordEmail(input: $input)
  }
`;
