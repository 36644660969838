import { gql } from "@apollo/client";

// This is for local-only fields
const typeDefs = gql`
  extend type Price {
    isPersisted: Boolean!
  }

  extend type Plan {
    isPersisted: Boolean!
  }
`;

export default typeDefs;
