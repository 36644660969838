import React from "react";
import * as S from "./styles";

export type placementTypes = "top" | "bottom" | "right" | "left";

interface TooltipProps {
  /**
   * Tooltip reference element.
   */
  children: React.ReactElement;
  /**
   * Tooltip placement
   */
  placement?: placementTypes;
  /**
   * Content to be shown
   */
  content: React.ReactChild | React.ReactChild[];
  /**
   * Determines if an arrow is shown <br>
   * If true, adds an arrow to the tooltip.
   */
  showArrow?: boolean;
  /**
   * Duration in ms of the transition animation.
   */
  duration?: number | [number, number];
}

const Tooltip = (props: TooltipProps) => {
  const { children, placement, content, showArrow, duration } = props;

  return (
    <S.Tooltip
      content={content}
      placement={placement}
      arrow={showArrow && "<span id='arrow'/>"}
      interactive
      duration={duration}
    >
      <S.Container>{children}</S.Container>
    </S.Tooltip>
  );
};

Tooltip.defaultProps = {
  placement: "right",
  showArrow: false,
  duration: [300, 250],
};

export default Tooltip;
