import React, { Suspense } from "react";
import Loader from "components/Loader";

export interface AsyncComponentProps {
  LazyComponent: React.LazyExoticComponent<() => React.ReactElement>;
}

const AsyncComponent = ({ LazyComponent }: AsyncComponentProps) => {
  return (
    <Suspense fallback={<Loader fillSpace />}>
      <LazyComponent />
    </Suspense>
  );
};

export default AsyncComponent;
