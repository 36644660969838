import React, { useCallback, useState, useEffect, useMemo } from "react";
import copy from "copy-to-clipboard";
import { colors } from "styles";
import { Tooltip } from "../Tooltip";
import { Icon, SizeMap } from "./styles";

interface ContentCopyProps {
  copyData: string;
  colorOverride?: keyof typeof colors;
  iconSize?: keyof typeof SizeMap;
}

const ContentCopy = ({
  copyData,
  colorOverride,
  iconSize,
}: ContentCopyProps) => {
  const [copied, setCopied] = React.useState(false);

  const copyToClipboard = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();

      copy(copyData);
      setCopied(true);
    },
    [copyData]
  );

  /**
   * When updating content dynamically, Tippy.js will create separate instances and sometimes conflict.
   * We need to make sure that only one state is able to render at a time.
   *
   * TODO: Find better fix for this.
   */
  return (
    <>
      {!copied ? (
        <Tooltip content="Copy" duration={[0, 0]}>
          <div
            aria-hidden="true"
            onClick={copyToClipboard}
            className="table"
            onMouseLeave={() => setCopied(false)}
          >
            <Icon
              $colorOverride={colorOverride}
              size={iconSize}
              data-testid="copy-icon"
            />
          </div>
        </Tooltip>
      ) : null}
      {copied ? (
        <Tooltip content="Copied!" duration={[0, 0]}>
          <div
            aria-hidden="true"
            onClick={copyToClipboard}
            className="table"
            onMouseLeave={() => setCopied(false)}
          >
            <Icon
              $colorOverride={colorOverride}
              size={iconSize}
              data-testid="copy-icon"
            />
          </div>
        </Tooltip>
      ) : null}
    </>
  );
};

ContentCopy.defaultProps = {
  colorOverride: "",
  iconSize: "medium",
};

export default ContentCopy;
