import React from "react";
import { useLocalStorage } from "hooks/useLocalStorage";

enum Env {
  LIVE = "live",
  SANDBOX = "sandbox",
}

const EnvContext = React.createContext(null);

const useEnvContext = () => {
  return React.useContext(EnvContext);
};

const EnvContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [storedEnv, setStoredEnv] = useLocalStorage("_ms-env", Env.LIVE);
  const [env, setEnv] = React.useState({
    isSandbox: storedEnv === Env.SANDBOX,
    isLive: storedEnv === Env.LIVE,
  });

  const setEnvs = (newEnv: { isSandbox: boolean; isLive: boolean }) => {
    setEnv(newEnv);
    setStoredEnv(env.isLive ? Env.LIVE : Env.SANDBOX);
  };

  React.useEffect(() => {
    if (window.location.pathname.startsWith("/sandbox/") && !env.isSandbox) {
      setEnvs({
        isSandbox: true,
        isLive: false,
      });
    } else if (!env.isLive) {
      setEnvs({
        isSandbox: false,
        isLive: true,
      });
    }
  }, []);

  const toggleEnv = () => {
    const newEnv = {
      isSandbox: !env.isSandbox,
      isLive: !env.isLive,
    };

    setEnvs(newEnv);

    return newEnv;
  };

  return (
    <EnvContext.Provider value={{ env, toggleEnv }}>
      {children}
    </EnvContext.Provider>
  );
};

export { useEnvContext, EnvContextProvider };
