import tw, { css, styled } from "twin.macro";
import { darken } from "polished";

const text = {
  default: "white",
  skeleton: "grey700",
  transparent: "blue400",
  highlight: "white",
  icon: "black",
};

const background = {
  default: "primary",
  skeleton: "white",
  transparent: "transparent",
  highlight: "green500",
  icon: "white",
};

const states = {
  disabled: tw`cursor-default border-app-gray200 bg-app-gray300 text-white`,
  noBorder: tw`border-none`,
  noPadding: tw`p-0`,
};

interface ButtonsProps {
  variant: "default" | "transparent" | "skeleton" | "highlight" | "icon";
  alignSelf: "start" | "center" | "end" | "initial";
  disabled: boolean;
  noBorder: boolean;
  noPadding: boolean;
  noOutline: boolean;
  colorOverride: string;
  backgroundColorOverride: string;
}

export const Button = styled.button<ButtonsProps>(() => [
  tw`flex justify-center relative border font-medium overflow-hidden `,
  ({
    theme: { colors = {} },
    variant,
    alignSelf,
    colorOverride,
    backgroundColorOverride,
    noBorder,
    noOutline,
  }) => css`
    padding: ${variant === "transparent" ? "0px" : "8px"};
    font-size: 14px;
    line-height: 16px;
    align-self: ${alignSelf};
    border-radius: 4px;
    font-weight: bold;
    color: ${colors[colorOverride] || colors[text[variant]]};
    background-color: ${(backgroundColorOverride &&
      colors[backgroundColorOverride]) ||
    (variant === "default" && colors.primary400) ||
    colors[background[variant]] ||
    "transparent"};
    border-width: ${(variant === "skeleton" && "1px") ||
    (variant === "icon" && "1px") ||
    "0px"};
    border-style: ${(variant === "skeleton" && "solid") ||
    (variant === "icon" && "solid") ||
    "none"};
    border-color: ${(variant === "skeleton" && colors.grey200) ||
    (variant === "icon" && colors.grey100) ||
    "unset"};
    &:focus {
      background-color: ${(backgroundColorOverride &&
        darken(0.05, colors[backgroundColorOverride])) ||
      (variant === "default" && colors.primary800) ||
      (variant === "highlight" && colors.green800) ||
      (variant === "transparent" && "transparent") ||
      colors.white};
      outline-width: ${variant === "transparent" || noOutline ? "0px" : "4px"};
      outline-style: ${variant === "transparent" || noOutline
        ? "none"
        : "solid"};
      outline-color: ${((variant === "transparent" || noOutline) && "unset") ||
      (variant === "highlight" && colors.green100) ||
      (variant === "default" && colors.primary100) ||
      colors.blue100};
      border-color: transparent;
    }
    &:hover:not(:disabled) {
      color: ${(colorOverride && darken(0.05, colors[colorOverride])) ||
      (variant === "transparent" && colors.primary500) ||
      (variant === "skeleton" && colors.black) ||
      (variant === "icon" && colors.black) ||
      colors.white};
      background-color: ${(backgroundColorOverride &&
        darken(0.05, colors[backgroundColorOverride])) ||
      (variant === "default" && colors.primary700) ||
      (variant === "highlight" && colors.green800) ||
      (variant === "transparent" && "transparent") ||
      colors.grey100};
    }
    &:disabled {
      color: ${(variant === "default" && colors.white) ||
      (variant === "highlight" && colors.white) ||
      colors.grey500};
      background: ${(variant === "default" && colors?.primary200) ||
      (variant === "highlight" && colors?.green200) ||
      (variant === "skeleton" && colors?.grey200) ||
      (variant === "icon" && colors?.white) ||
      "transparent"};
      border-width: ${variant === "icon" ? "1px" : "0px"};
      border-style: ${variant === "icon" ? "solid" : "none"};
      border-color: ${variant === "icon" ? colors.grey100 : "none"};
      & > svg {
        fill: ${colors.grey500};
      }
    }
    & > svg {
      height: 18px;
      width: 18px;
    }
  `,
  ({ noBorder }) => noBorder && states.noBorder,
  ({ disabled }) => disabled && states.disabled,
  ({ noPadding }) => noPadding && states.noPadding,
]);

type LoadingProps = Pick<ButtonsProps, "variant" | "backgroundColorOverride">;

export const LoaderContainer = styled.div<LoadingProps>(
  ({ theme: { colors }, variant, backgroundColorOverride }) => css`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(backgroundColorOverride &&
      colors[backgroundColorOverride]) ||
    (variant === "default" && colors.primary400) ||
    colors[background[variant]] ||
    "transparent"};
  `
);
