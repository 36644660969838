import { fontSizes, fontWeights } from "./fonts";
import { colors } from "./colors";
import { variables } from "./variables";

const livePalette = {
  primary: colors.blue400,
  primaryDark: colors.blue700,
  primaryLight: colors.blue300,
  primaryTextActive: colors.white,
  primary050: colors.blue050,
  primary100: colors.blue100,
  primary200: colors.blue200,
  primary300: colors.blue300,
  primary400: colors.blue400,
  primary500: colors.blue500,
  primary600: colors.blue600,
  primary700: colors.blue700,
  primary800: colors.blue800,
  primary900: colors.blue900,
}

// The old sandbox styles partly relied on defining `blue1/blue1Active` as sandbox purples, so some fixes
// in components will be needed.
const sandboxPalette = {
  primary: colors.purple600,
  primaryDark: colors.purple800,
  primaryLight: colors.purple500,
  primaryTextActive: colors.white,
  primary050: colors.purple050,
  primary100: colors.purple100,
  primary200: colors.purple200,
  primary300: colors.purple300,
  primary400: colors.purple400,
  primary500: colors.purple500,
  primary600: colors.purple600,
  primary700: colors.purple700,
  primary800: colors.purple800,
  primary900: colors.purple900,
}

// Call to actions (CTA). Same for both modes.
const ctaPalette = {
  secondary: colors.green500,
  secondaryDark: colors.green600,
  secondaryLight: colors.green400,
  secondaryTextActive: colors.white,
  secondary050: colors.green050,
  secondary100: colors.green100,
  secondary200: colors.green200,
  secondary300: colors.green300,
  secondary400: colors.green400,
  secondary500: colors.green500,
  secondary600: colors.green600,
  secondary700: colors.green700,
  secondary800: colors.green800,
  secondary900: colors.green900,
};

export const theme = {
  env: "live",
  colors: {
    ...colors,
    ...livePalette,
    ...ctaPalette,
  },
  fontSizes,
  fontWeights,
  variables,
};

export const sandboxTheme = {
  env: "sandbox",
  colors: {
    ...colors,
    ...sandboxPalette,
    ...ctaPalette,
  },
  fontSizes,
  fontWeights,
  variables,
};
