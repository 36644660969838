/**
 * Colors of same name are ranked from 1 (lightest) to darkest
 */

// Highlight Green Tints (CTAs)
const green = {
  green050: "#E4F7E9",
  green100: "#BFEBC8",
  green200: "#94DFA4",
  green300: "#62D37F",
  green400: "#2EC861",
  green500: "#00BD43", // Default Color for live
  green600: "#00AE39",
  green700: "#009B2D",
  green800: "#008A20",
  green900: "#006A09",
};

// Sandbox mode
const purple = {
  purple050: "#F0E6FD",
  purple100: "#D9C1FA",
  purple200: "#BF96F8",
  purple300: "#A469F6",
  purple400: "#8C42F4",
  purple500: "#6908F0",
  purple600: "#6200EA", // Default Color for sandbox
  purple700: "#5E00E2",
  purple800: "#5C00DD",
  purple900: "#4400A3",
};

// Live mode
const blue = {
  blue050: "#E7F1FF",
  blue100: "#C2DAFE",
  blue200: "#96BFFE",
  blue300: "#62A1FF",
  blue400: "#2962FF", // Default Color for blue
  blue500: "#0044FC",
  blue600: "#003BF0",
  blue700: "#002FE3",
  blue800: "#0022D9",
  blue900: "#0001C0",
};

// Highlight grey Tints (CTAs)
const grey = {
  grey050: "#F6F6F6",
  grey100: "#EBEBEB",
  grey200: "#DDDDDD",
  grey300: "#C9C9C9",
  grey400: "#A4A4A4", // Default Color for grey
  grey500: "#828282",
  grey600: "#5C5C5C",
  grey700: "#494949",
  grey800: "#2C2C2C",
  grey900: "#0A0A0A",
};

// App Background
const backgrounds = {
  app: "#FAFAFA",
};

// Error
const error = {
  errorFill: "#EE4F4F",
  errorLight: "#F3B2B2",
};

// Orange
const tags = {
  orangeTagBg: "#FAF3E1",
  orangeTagText: "#E28C0A",
  redTagBg: "#FFE4E4",
};

const greys = {
  grey1: "#f1f5fb",
  grey2: "#d9e1eb",
  grey3: "#cccccc",
  grey4: "#a6a6a6",
};

const blues = {
  blue1: "#008ff3",
  blue1Active: "#0077ca",
};

const reds = {
  red1: "#FFE1DA",
  red2: "#F54C27",
};

const greens = {
  green1: "#28C800",
  green2: "#25B700",
};

const yellows = {
  yellow1: "#E7D21C",
};

const purples = {
  purple1: "#6740EC",
  purple2: "#F2EEFE",
};

export const colors = {
  white: "#fff",
  black: "#00213e",
  ...greys,
  ...blues,
  ...reds,
  ...greens,
  ...yellows,
  ...purples,
  ...green,
  ...grey,
  ...purple,
  ...blue,
  ...backgrounds,
  ...error,
  ...tags,
};
