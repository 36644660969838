import {
  QuestionMarkCircleIcon as QuestionMarkOutline,
  InformationCircleIcon as InformationCircleOutline,
} from "@heroicons/react/outline";
import {
  QuestionMarkCircleIcon as QuestionMarkSolid,
  InformationCircleIcon as InformationCircleSolid,
} from "@heroicons/react/solid";
import React from "react";
import mergeClassNames from "../../helpers/mergeClassNames/mergeClassNames";
import { sizeMap, SizeMapTypes } from "../../constants";

import Tooltip, { placementTypes } from "./Tooltip";

type IconVariant = "outline" | "solid";
type IconType = "info" | "questionMark";

interface InfoTooltipProps {
  className?: string;
  /**
   * Tooltip placement
   */
  placement?: placementTypes;
  /**
   * content to be shown
   */
  content: React.ReactChild | React.ReactChild[];
  /**
   * Determines if an arrow is shown <br>
   * If true, adds an arrow to the tooltip.
   */
  showArrow?: boolean;
  /**
   * variant can be `normal` or `outlined`
   */
  variant?: IconVariant;
  /**
   * size of the help icon <b>
   * Here are the available sizes @sizeMapTypes
   */
  size?: SizeMapTypes;
  /**
   * type of icon to be rendered `info` or `help` <br>
   * default is info
   */
  iconType?: IconType;
}

const InfoTooltip = (props: InfoTooltipProps) => {
  const { placement, content, showArrow, variant, className, size, iconType } =
    props;

  const renderIcon = React.useMemo(() => {
    if (iconType === "questionMark") {
      return variant === "solid" ? (
        <QuestionMarkSolid
          className={mergeClassNames(sizeMap[size], className)}
        />
      ) : (
        <QuestionMarkOutline
          className={mergeClassNames(sizeMap[size], className)}
        />
      );
    }
    return variant === "solid" ? (
      <InformationCircleSolid
        className={mergeClassNames(sizeMap[size], className)}
      />
    ) : (
      <InformationCircleOutline
        className={mergeClassNames(sizeMap[size], className)}
      />
    );
  }, [className, size, variant, iconType]);

  return (
    <Tooltip content={content} showArrow={showArrow} placement={placement}>
      {renderIcon}
    </Tooltip>
  );
};

InfoTooltip.defaultProps = {
  placement: "right",
  showArrow: false,
  variant: "outline",
  size: "small",
  className: undefined,
  iconType: "info",
};

export default InfoTooltip;
