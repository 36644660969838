import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useEnvContext } from "containers/Environment";
import { createPath, RouteName } from "enums/routes";
import Loader from "components/Loader";
import { AuthWrapper } from "./helpers";

const LazyLogin = React.lazy(() => import("containers/Login"));
const LazyReset = React.lazy(() => import("containers/Reset"));
const LazySignup = React.lazy(() => import("containers/Signup"));
const LazyApps = React.lazy(() => import("containers/Routes/AppRoutes"));
const LazyStripeConnect = React.lazy(
  () => import("containers/Settings/StripeConnect")
);

export const MainRoutes = () => {
  const { env } = useEnvContext();

  const pages = [
    // Public pages
    {
      exact: true,
      path: createPath(RouteName.login, env),
      Component: LazyLogin,
    },
    {
      exact: true,
      path: createPath(RouteName.reset, env),
      Component: LazyReset,
    },
    {
      exact: true,
      path: createPath(RouteName.signup, env),
      Component: LazySignup,
    },
    // Authenticated pages
    {
      exact: true,
      path: createPath(RouteName.home, env),
      // eslint-disable-next-line react/display-name
      Component: () => <Redirect to={createPath(RouteName.apps, env)} />,
      isAuth: true,
    },
    {
      exact: false,
      path: createPath(RouteName.apps, env),
      Component: LazyApps,
      isAuth: true,
    },
    {
      exact: true,
      path: createPath(RouteName.stripeConnect, env),
      Component: LazyStripeConnect,
      isAuth: true,
    },
  ];

  return (
    <Suspense fallback={<Loader fillSpace />}>
      <Switch>
        {pages.map(({ exact, path, Component, isAuth }) => (
          <Route key={path} exact={exact} path={path}>
            <AuthWrapper isAuth={isAuth}>
              <Component />
            </AuthWrapper>
          </Route>
        ))}
      </Switch>
    </Suspense>
  );
};
