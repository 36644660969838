import * as Types from '../../generatedTypes';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateUserProfileMutationVariables = Types.Exact<{
  input: Types.UpdateUserProfileInput;
}>;


export type UpdateUserProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateUserProfile: (
    { __typename?: 'User' }
    & Pick<Types.User, 'id'>
    & { auth: (
      { __typename?: 'UserAuth' }
      & Pick<Types.UserAuth, 'email'>
    ), profile: (
      { __typename?: 'UserProfile' }
      & Pick<Types.UserProfile, 'firstName' | 'lastName'>
    ) }
  ) }
);

export type ResetUserPasswordMutationVariables = Types.Exact<{
  input: Types.UserResetPasswordInput;
}>;


export type ResetUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetUserPassword: (
    { __typename?: 'User' }
    & Pick<Types.User, 'id'>
  ) }
);

export type SendUserResetPasswordEmailMutationVariables = Types.Exact<{
  input: Types.UserResetPasswordEmailInput;
}>;


export type SendUserResetPasswordEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'sendUserResetPasswordEmail'>
);


export const UpdateUserProfileDocument = gql`
    mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
  updateUserProfile(input: $input) {
    id
    auth {
      email
    }
    profile {
      firstName
      lastName
    }
  }
}
    `;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, baseOptions);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const ResetUserPasswordDocument = gql`
    mutation ResetUserPassword($input: UserResetPasswordInput!) {
  resetUserPassword(input: $input) {
    id
  }
}
    `;
export type ResetUserPasswordMutationFn = Apollo.MutationFunction<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;

/**
 * __useResetUserPasswordMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPasswordMutation, { data, loading, error }] = useResetUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>) {
        return Apollo.useMutation<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>(ResetUserPasswordDocument, baseOptions);
      }
export type ResetUserPasswordMutationHookResult = ReturnType<typeof useResetUserPasswordMutation>;
export type ResetUserPasswordMutationResult = Apollo.MutationResult<ResetUserPasswordMutation>;
export type ResetUserPasswordMutationOptions = Apollo.BaseMutationOptions<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>;
export const SendUserResetPasswordEmailDocument = gql`
    mutation SendUserResetPasswordEmail($input: UserResetPasswordEmailInput!) {
  sendUserResetPasswordEmail(input: $input)
}
    `;
export type SendUserResetPasswordEmailMutationFn = Apollo.MutationFunction<SendUserResetPasswordEmailMutation, SendUserResetPasswordEmailMutationVariables>;

/**
 * __useSendUserResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useSendUserResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendUserResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendUserResetPasswordEmailMutation, { data, loading, error }] = useSendUserResetPasswordEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendUserResetPasswordEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendUserResetPasswordEmailMutation, SendUserResetPasswordEmailMutationVariables>) {
        return Apollo.useMutation<SendUserResetPasswordEmailMutation, SendUserResetPasswordEmailMutationVariables>(SendUserResetPasswordEmailDocument, baseOptions);
      }
export type SendUserResetPasswordEmailMutationHookResult = ReturnType<typeof useSendUserResetPasswordEmailMutation>;
export type SendUserResetPasswordEmailMutationResult = Apollo.MutationResult<SendUserResetPasswordEmailMutation>;
export type SendUserResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<SendUserResetPasswordEmailMutation, SendUserResetPasswordEmailMutationVariables>;