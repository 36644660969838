import React from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyle, theme, sandboxTheme } from "styles";
import { ToastAlertContextProvider } from "components/toastAlert";
import { ConfirmationContextProvider } from "components/confirmation";
import { useEnvContext } from "containers/Environment";
import { IntlProvider } from "react-intl";
import { MainRoutes } from "containers/Routes";
import { BrowserRouter } from "react-router-dom";
import { translations } from "../../translations";

const App = () => {
  const { env } = useEnvContext();
  const locale = navigator.language.split(/[-_]/)[0];
  const messages = translations[locale];

  const [activeTheme, setActiveTheme] = React.useState(theme);
  React.useEffect(() => {
    if (env.isSandbox) {
      setActiveTheme(sandboxTheme);
    } else {
      setActiveTheme(theme);
    }
  }, [env]);

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={activeTheme}>
        <IntlProvider locale={locale} messages={messages} defaultLocale="en">
          <ConfirmationContextProvider>
            <ToastAlertContextProvider>
              <BrowserRouter>
                <MainRoutes />
              </BrowserRouter>
            </ToastAlertContextProvider>
          </ConfirmationContextProvider>
        </IntlProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
