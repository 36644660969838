import tw, { css, styled } from "twin.macro";

interface HeadingProps {
  isCentered: boolean;
  bold: boolean;
}

const sharedClassNames = "flex items-center";

export const Heading1 = styled.h1<HeadingProps>(() => [
  tw`${sharedClassNames}`,
  ({ isCentered, bold }) => css`
    font-size: 29px;
    line-height: 36px;
    font-weight: ${bold ? "bold" : "normal"};
    justify-content: ${isCentered && "center"};
  `,
]);

export const Heading2 = styled.h2<HeadingProps>(() => [
  tw`${sharedClassNames}`,
  ({ isCentered, bold }) => css`
    font-size: 24px;
    line-height: 32px;
    font-weight: ${bold ? "bold" : "normal"};
    justify-content: ${isCentered && "center"};
  `,
]);

export const Heading3 = styled.h3<HeadingProps>(() => [
  tw`${sharedClassNames}`,
  ({ isCentered, bold }) => css`
    font-size: 20px;
    line-height: 32px;
    font-weight: ${bold ? "bold" : "normal"};
    justify-content: ${isCentered && "center"};
  `,
]);

export const Heading4 = styled.h4<HeadingProps>(() => [
  tw`${sharedClassNames}`,
  ({ isCentered, bold }) => css`
    font-size: 17px;
    line-height: 28px;
    font-weight: ${bold ? "bold" : "normal"};
    justify-content: ${isCentered && "center"};
  `,
]);
