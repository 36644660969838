import React, { useState, useContext, useEffect } from "react";
import { useTransition } from "react-spring";
import { springConfigs } from "styles";
import ToastAlert from "../ToastAlert";
import * as S from "./styles";

interface Toast {
  message?: string;
  alertType: "success" | "error" | "warning";
}

interface DefaultContext {
  createToastAlert: (toast: Toast) => void;
}

const defaultContext: DefaultContext = {
  createToastAlert: undefined,
};

const ToastAlertContext = React.createContext(defaultContext);

const useToastAlertContext = () => {
  return useContext(ToastAlertContext);
};

const ToastAlertContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [toast, setToast] = useState<Toast>();
  const TIMEOUT = 5000;
  let timeoutId: null | ReturnType<typeof setTimeout>;

  const handleClose = () => {
    setToast(null);
  };

  const createToastAlert = (t: Toast) => {
    handleClose();
    setTimeout(() => {
      setToast(t);
    });
  };

  const transitions = useTransition(toast, null, {
    config: { ...springConfigs.mediumTension },
    from: { opacity: 0, bottom: -100, transform: "scale(0.9, 0.9)" },
    enter: { opacity: 1, bottom: 0, transform: "scale(1, 1)" },
    leave: { opacity: 0, bottom: -100, transform: "scale(0.9, 0.9)" },
  });

  const renderToast = () =>
    transitions.map(
      ({ item, key, props }) =>
        item && (
          <S.ToastContainer key={key} style={props}>
            <ToastAlert
              message={item.message}
              alertType={item.alertType}
              onClose={handleClose}
              timeout={TIMEOUT}
            />
          </S.ToastContainer>
        )
    );

  useEffect(() => {
    if (toast) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeoutId = setTimeout(() => {
        handleClose();
      }, TIMEOUT);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [toast]);

  return (
    <ToastAlertContext.Provider value={{ createToastAlert }}>
      {renderToast()}
      {children}
    </ToastAlertContext.Provider>
  );
};

export { useToastAlertContext, ToastAlertContextProvider };
