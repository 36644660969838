import { ExclamationCircleIcon } from "@heroicons/react/solid";
import tw, { styled, css, theme } from "twin.macro";
import Visibility from "assets/icons/Visibility.svg";
import VisibilityOff from "assets/icons/VisibilityOff.svg";

export const InputContainer = styled.div<{ $error: boolean }>(() => [
  tw`flex relative rounded-md shadow-sm  border  border-app-gray200 bg-white`,
  css`
    border-radius: 6px;
    &:focus-within {
      border: 1px solid ${theme`colors.app-blue500`};
      outline: 1px solid ${theme`colors.app-blue500`};
    }
    &:focus-within input {
      outline: none;
      box-shadow: none;
      border-color: ${theme`colors[app-gray300]`};
      -webkit-box-shadow: none;
    }
  `,
  ({ $error }) =>
    $error &&
    css`
      border: 1px solid ${theme`colors[error-light]`} !important;
      color: ${theme`colors[error-fill]`};
      &:focus-within {
        border: 1px solid ${theme`colors[error-fill]`} !important;
        outline: 1px solid ${theme`colors[error-fill]`} !important;
      }
    `,
  ({ $error }) => $error && tw`placeholder-red-300`,
]);

export const LabelContainer = tw.div`flex justify-between items-center mb-2`;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  line-height: 16px;
`;

interface InputElementProps {
  $error: string;
  $isLeadingElement: boolean;
  $isTrailingElement: boolean;
  $leadingInline: boolean;
  $trailingInline: boolean;
  $padding: { top?: string; right?: string; bottom?: string; left?: string };
}

export const variantStyles = {
  leadingOutline: tw`rounded-l-none border-l`,
  leadingInline: tw`rounded-l-none`,
  trailingOutline: tw`rounded-r-none border-r`,
  trailingInline: tw`rounded-r-none`,
};
export const InputElement = styled.input<InputElementProps>(() => [
  ({
    $padding: { top = "12px", right = "12px", bottom = "12px", left = "12px" },
  }) => css`
    z-index: 1;
    font-size: 14px;
    line-height: 16px;
    padding: ${top} ${right} ${bottom} ${left};
    border-width: 0;
  `,
  tw`block w-full border-app-gray200 flex-1 min-w-0  bg-transparent`,
  ({ $isLeadingElement, $leadingInline }) =>
    $isLeadingElement && !$leadingInline && variantStyles.leadingOutline,
  ({ $isLeadingElement, $leadingInline }) =>
    $isLeadingElement && $leadingInline && variantStyles.leadingInline,
  ({ $isTrailingElement, $trailingInline }) =>
    $isTrailingElement && !$trailingInline && variantStyles.trailingOutline,
  ({ $isTrailingElement, $trailingInline }) =>
    $isTrailingElement && $trailingInline && variantStyles.trailingInline,
]);

export const SubText = tw.p`mt-2 text-sm text-app-gray500`;

// Error
export const ErrorIcon = tw(ExclamationCircleIcon)`h-5 w-5 text-red-500`;
export const ErrorText = tw.p`mt-2 text-sm text-red-600`;

export const CornerHintText = tw.span`text-sm text-app-gray500`;

export const VisibilityOnIcon = tw(Visibility)`h-5 w-5 text-app-gray400`;
export const VisibiliyOffIcon = tw(VisibilityOff)`h-5 w-5 text-app-gray400`;

// Leading
export const LeadingOutlineContainer = styled.div(() => [
  tw`inline-flex items-center rounded-l-md rounded-r-none! border border-r-0 border-app-gray200 bg-app-gray50 text-app-gray500 sm:text-sm border-0`,
]);

export const LeadingInlineContainer = styled.div<{
  error: boolean;
}>(() => [
  tw`inline-flex items-center rounded-l-md border border-r-0 border-app-gray200  bg-white text-app-gray500 sm:text-sm border-0`,
  ({ error }) => error && tw`border-red-300`,
]);
export const LeadingInline = tw.span`flex text-app-gray500 sm:text-sm`;

// Trailing
export const TrailingOutlineContainer = styled.div(() => [
  tw`flex items-center rounded-r-md rounded-l-none! border border-l-0 border-app-gray200 bg-app-gray50 text-app-gray500 sm:text-sm border-0`,
]);
export const TrailingInlineContainer = styled.div<{
  error: boolean;
}>(() => [
  tw`flex items-center rounded-r-md border border-l-0 border-app-gray200 bg-white text-app-gray500 sm:text-sm border-0`,
  ({ error }) => error && tw`border-red-300`,
]);
export const TrailingInline = tw.span`flex text-app-gray500 sm:text-sm`;

export const TrailingIconContainer = styled.div`
  background: transparent;
  border: none;
  color: ${({ theme: themeProp }) => themeProp.colors.grey3};
  pointer-events: auto;
  cursor: pointer;
`;

export const TrailingIcon = styled.div`
  height: 1.25rem;
  width: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 100%;
    width: 80%;
  }
`;
