import React from "react";
import {
  AmericanExpress,
  DinersClub,
  DiscoverCard,
  JcbCard,
  MasterCard,
  UnionPayCard,
  Visa,
} from "components/Icons";

export const mapCardBrandToIcon = {
  VISA: <Visa />,
  MASTERCARD: <MasterCard />,
  AMEX: <AmericanExpress className="w-8 h-8" />,
  DINERS_CLUB: <DinersClub className="w-8 h-8" />,
  DISCOVER: <DiscoverCard className="w-8 h-8" />,
  UNION_PAY: <UnionPayCard className="w-8 h-8" />,
  JCB: <JcbCard className="w-8 h-8" />,
};
