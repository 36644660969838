import styled from "styled-components";

interface SpacerProps {
  spacingSize: string;
  isVertical: boolean;
}

const getMargin = ({ spacingSize, isVertical }: SpacerProps) => {
  if (!isVertical) return `0 ${spacingSize} 0 0`;

  return `${spacingSize} 0 0 0`;
};

export const Spacer = styled.div<SpacerProps>`
  margin: ${(props) => getMargin(props)};
  flex-shrink: 0;
`;
