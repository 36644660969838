import React from "react";
import { IconProps } from "./Icons.type";

const MasterCard: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>MasterCard</title>
      <path
        d="M16.7042 5.30615H11.2961V14.9063H16.7042V5.30615Z"
        fill="#FF5F00"
      />
      <path
        d="M11.6395 10.1054C11.6386 9.18082 11.8507 8.26819 12.2597 7.43657C12.6687 6.60495 13.2638 5.87615 14.0001 5.30533C13.0883 4.59739 11.9933 4.15713 10.8402 4.03487C9.68714 3.91261 8.52248 4.11329 7.47939 4.61398C6.43629 5.11466 5.55684 5.89515 4.94156 6.86622C4.32628 7.8373 4 8.95978 4 10.1054C4 11.251 4.32628 12.3735 4.94156 13.3445C5.55684 14.3156 6.43629 15.0961 7.47939 15.5968C8.52248 16.0975 9.68714 16.2981 10.8402 16.1759C11.9933 16.0536 13.0883 15.6134 14.0001 14.9054C13.2638 14.3346 12.6687 13.6058 12.2597 12.7742C11.8507 11.9426 11.6386 11.0299 11.6395 10.1054Z"
        fill="#EB001B"
      />
      <path
        d="M24 10.1054C24 11.251 23.6738 12.3734 23.0585 13.3445C22.4433 14.3156 21.5639 15.0961 20.5208 15.5968C19.4777 16.0975 18.3131 16.2981 17.16 16.1759C16.0069 16.0536 14.9119 15.6134 14.0002 14.9054C14.7358 14.334 15.3305 13.6051 15.7394 12.7736C16.1483 11.9421 16.3608 11.0298 16.3608 10.1054C16.3608 9.18097 16.1483 8.26862 15.7394 7.43713C15.3305 6.60565 14.7358 5.87673 14.0002 5.30533C14.9119 4.59738 16.0069 4.15712 17.16 4.03487C18.3131 3.91261 19.4777 4.1133 20.5208 4.61399C21.5639 5.11468 22.4433 5.89517 23.0585 6.86624C23.6738 7.83732 24 8.9598 24 10.1054Z"
        fill="#F79E1B"
      />
    </svg>
  );
};

export default MasterCard;
