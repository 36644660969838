export enum RouteName {
  home = "home",
  plans = "plans",
  login = "login",
  reset = "reset",
  signup = "signup",
  members = "members",
  coupons = "coupons",
  billing = "billing",
  invoices = "invoices",
  settings = "settings",
  apps = "apps",
  templates = "templates",
  community = "community",
  developers = "developers",
  permissions = "permissions",
  integrations = "integrations",
  webhooks = "webhooks",
  stripeConnect = "stripeConnect",
  editor = "editor",
  authentication = "authentication",
  devtools = "devtools",
}

const routes = {
  [RouteName.home]: {
    path: "/",
    withSandboxPrefix: false,
  },
  [RouteName.plans]: {
    path: "/plans",
    withSandboxPrefix: false,
  },
  [RouteName.login]: {
    path: "/login",
    withSandboxPrefix: false,
  },
  [RouteName.reset]: {
    path: "/reset",
    withSandboxPrefix: false,
  },
  [RouteName.signup]: {
    path: "/signup",
    withSandboxPrefix: false,
  },
  [RouteName.members]: {
    path: "/members",
    withSandboxPrefix: false,
  },
  [RouteName.coupons]: {
    path: "/coupons",
    withSandboxPrefix: false,
  },
  [RouteName.billing]: {
    path: "/billing",
    withSandboxPrefix: false,
  },
  [RouteName.invoices]: {
    path: "/invoices",
    withSandboxPrefix: false,
  },
  [RouteName.settings]: {
    path: "/settings",
    withSandboxPrefix: false,
  },
  [RouteName.apps]: {
    path: "/apps",
    withSandboxPrefix: true,
  },
  [RouteName.webhooks]: {
    path: "/devtools/webhooks",
    withSandboxPrefix: false
  },
  [RouteName.templates]: {
    path: "/templates",
    withSandboxPrefix: false,
  },
  [RouteName.community]: {
    path: "/community",
    withSandboxPrefix: false,
  },
  [RouteName.developers]: {
    path: "/developers",
    withSandboxPrefix: true,
  },
  [RouteName.permissions]: {
    path: "/permissions",
    withSandboxPrefix: false,
  },
  [RouteName.integrations]: {
    path: "/integrations",
    withSandboxPrefix: false,
  },
  [RouteName.stripeConnect]: {
    path: "/stripe/callback",
    withSandboxPrefix: false,
  },
  [RouteName.editor]: {
    path: "/editor",
    withSandboxPrefix: false,
  },
  [RouteName.authentication]: {
    path: "/authentication",
    withSandboxPrefix: false,
  },
  [RouteName.devtools]: {
    path: "/devtools",
    withSandboxPrefix: false,
  }
};

export const createPath = (
  route: RouteName,
  env = { isSandbox: false, isLive: true }
) => {
  const { path, withSandboxPrefix } = routes[route];
  const prefix = env.isSandbox && withSandboxPrefix ? "/sandbox" : "";

  return `${prefix}${path}`;
};
