const useContextHelpers = () => {
  const updateNested = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj: any,
    path: (string | number)[],
    value: unknown
  ): object => {
    const currentKey = path[0];
    const nextKeys = path.slice(1);

    if (Array.isArray(obj)) {
      /**
       * If the key is less than 0, it doesn't exist in the array. It can
       * instead be placed at the end of the array.
       */
      const indexKey = currentKey >= 0 ? currentKey : obj.length;

      return [
        ...obj.slice(0, indexKey as number),
        nextKeys.length
          ? updateNested(obj[currentKey as number], nextKeys, value)
          : value,
        ...obj.slice((indexKey as number) + 1),
      ].filter((val) => val);
    }

    return {
      ...obj,
      [currentKey]: nextKeys.length
        ? updateNested(obj[currentKey], nextKeys, value)
        : value,
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getNested = (obj: any, path: (string | number)[]): unknown => {
    const currentKey = path[0];
    const nextKeys = path.slice(1);

    if (typeof obj !== "object" || obj === null) {
      return undefined;
    }

    if (!path.length) {
      return obj;
    }

    return nextKeys.length
      ? getNested(obj[currentKey], nextKeys)
      : obj[currentKey];
  };

  return {
    updateNested,
    getNested,
  };
};

export default useContextHelpers;
