import React, { useMemo } from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import AsyncComponent, { AsyncComponentProps } from "components/AsyncComponent";

const LazySelector = React.lazy(
  () => import("containers/Component/Editor/Selector")
);
const LazyGlobal = React.lazy(
  () => import("containers/Component/Editor/Global")
);

const LazyColors = React.lazy(
  () => import("containers/Component/Editor/Global/Colors")
);

const LazyStyling = React.lazy(
  () => import("containers/Component/Editor/Global/Styling")
);

const LazyIndividual = React.lazy(
  () => import("containers/Component/Editor/Individual")
);

interface Page extends Partial<AsyncComponentProps> {
  exact: boolean;
  path: string;
  Component?: React.ReactNode;
}

export const SwitchHelper = ({ pages }: { pages: Page[] }) => (
  <Switch>
    {pages.map(({ exact, path: routePath, Component, LazyComponent }) => (
      <Route key={routePath} exact={exact} path={routePath}>
        {Component || <AsyncComponent LazyComponent={LazyComponent} />}
      </Route>
    ))}
  </Switch>
);

// TODO: Overhaul relative paths entirely
export const EditorSettingsRoutes = () => {
  const { path, url } = useRouteMatch();

  const pages = useMemo(
    () => [
      {
        exact: true,
        path: `${path}/`,
        // eslint-disable-next-line react/display-name
        Component: () => <Redirect to={`${url}/colors`} />,
      },
      {
        exact: true,
        path: `${path}/colors`,
        // eslint-disable-next-line react/display-name
        LazyComponent: LazyColors,
      },
      {
        exact: true,
        path: `${path}/styling`,
        // eslint-disable-next-line react/display-name
        LazyComponent: LazyStyling,
      },
    ],
    []
  );

  return <SwitchHelper pages={pages} />;
};

export const EditorRoutes = () => {
  const { path } = useRouteMatch();

  const pages = useMemo(
    () => [
      {
        exact: false,
        path: `${path}/global`,
        LazyComponent: LazyGlobal,
      },
      {
        exact: false,
        path: `${path}/components/:componentId`,
        LazyComponent: LazyIndividual,
      },
      {
        exact: true,
        path,
        // eslint-disable-next-line react/display-name
        LazyComponent: LazySelector,
      },
    ],
    []
  );

  return <SwitchHelper pages={pages} />;
};
