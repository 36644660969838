import styled from "styled-components";
import { animated } from "react-spring";

const WIDTH = 220;

export const ToastContainer = styled(animated.div)`
  width: ${WIDTH}px;
  position: fixed;
  left: calc(50% - ${WIDTH / 2}px);
  bottom: 0;
  z-index: 101;
  pointer-events: none;
`;
