import styled, { css, keyframes } from "styled-components";
import B from "components/Button";
import T from "components/Text";
import H from "components/Heading";
import { colors as c } from "styles";

type AlertType = "success" | "error" | "warning";

const alertTypeColors = {
  success: c.green1,
  error: c.red2,
  warning: c.yellow1,
};

export const CloseButton = styled(B)`
  position: absolute;
  top: -6px;
  right: -6px;
  height: 24px;
`;

export const ToastAlertContainer = styled.div`
  transform: translateY(calc(-100% - 30px));
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: all;

  ${CloseButton} {
    visibility: hidden;
    opacity: 0;
  }

  &:hover ${CloseButton} {
    visibility: visible;
    opacity: 1;
  }
`;

export const ToastAlert = styled.div`
  ${({ theme: { colors, variables } }) => css`
    padding: ${variables.gutterSmall};
    border-radius: ${variables.mainBorderRadius};
    box-shadow: ${variables.heavyBoxShadow};
    background-color: ${colors.black};
    color: ${colors.white};
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    word-wrap: break-word;
  `}
`;

const timeoutKeyFrames = keyframes`
  0% {
    right: 0;
  }
  100% {
    right: 100%;
  }
`;

export const TimeoutBar = styled.div<{ timeout: number; alertType: AlertType }>`
  ${({ theme: { variables }, timeout, alertType }) => css`
    position: absolute;
    bottom: 0;
    height: ${variables.gridUnit}px;
    left: 0;
    right: 0;
    background-color: #3f5467;

    &:after {
      content: "";
      position: absolute;
      height: ${variables.gridUnit}px;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${alertTypeColors[alertType]};
      animation: ${timeoutKeyFrames} ${timeout}ms linear;
      animation-fill-mode: forwards;
    }
  `}
`;

export const Heading = styled(H)<{ alertType: AlertType }>`
  color: ${({ alertType }) => alertTypeColors[alertType]};
  margin-bottom: ${({ theme: { variables } }) => variables.gridUnit}px;
`;

export const Text = styled(T)<{ alertType: AlertType }>`
  color: ${({ theme: { colors } }) => colors.white};
`;
