import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useMemo,
  useCallback,
} from "react";
import useGenerateComponentID from "hooks/useComponentId";
import ContentCopy from "components/ContentCopy";
import Spacer, { SpacerProps } from "components/Spacer";
import { InfoTooltip } from "components/Tooltip";
import * as S from "./styles";

export interface InputProps {
  /**
   * Minimum for range of numeric input
   */
  min?: string;
  /**
   * Maximum for range of numeric input
   */
  max?: string;
  /**
   * The type of input (this component implements textual types)
   */
  type?: string;
  /**
   * The step of numeric increment/decrement permitted
   */
  step?: string;
  /**
   * DOM name for the element
   */
  name?: string;
  /**
   * Value
   */
  value?: React.ReactText;
  /**
   * Label to appear above the input
   */
  label?: string;
  /**
   *
   */
  title?: string;
  /**
   * Regex pattern for input validation
   */
  pattern?: string;
  /**
   * Prevent modification and grey the input
   */
  disabled?: boolean;
  /**
   * Require the component in validation
   */
  required?: boolean;
  /**
   * Similar to disabled but without the styling change
   */
  readOnly?: boolean;
  /**
   * Ghost text to appear before any value is added
   */
  placeholder?: string;
  /**
   * Checks DOM element validity function before invoking callback
   */
  onlyAllowValidInput?: boolean;
  /**
   * onChange callback
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * onClick callback, useful for controlling click events in higher components
   */
  onClick?: (event: React.MouseEventHandler<HTMLInputElement>) => void;
  /**
   * Renders a copy button at the end of the input. This is overridden by leading elements.
   */
  allowCopy?: boolean;
  /**
   * Minimum length for text inputs
   */
  minLength?: number;
  /**
   * Maximum length for text inputs
   */
  maxLength?: number;
  /**
   * Hide stylized indicators like the 'Optional' tag and error symbol regardless of state
   */
  hideIndicators?: boolean;
  /**
   * Hint text to appear in the top-right corner, above the input. Replaces "Optional".
   */
  hintText?: string | React.ReactNode;
  /**
   * Text to appear as a hover info hint.
   */
  infoHintText?: string;
  /**
   * Text to appear under the input as a general description
   */
  subText?: string | React.ReactElement;
  /**
   * Text or element to appear in front of the input
   */
  leading?: React.ReactNode | string;
  /**
   * Toggle the visual demarcation for the leading element
   */
  leadingInline?: boolean;
  /**
   * Text or element to appear at the end of the input. Superseded in password mode.
   */
  trailing?: React.ReactNode | string;
  /**
   * Toggle the visual demarcation for the trailing element
   */
  trailingInline?: boolean;
  /**
   * Toggle preset padding on leading & trailing elements
   */
  disablePadding?: boolean;
  /**
   * Override padding on actual input element
   */
  inputPadding?: {
    left?: string;
    right?: string;
    top?: string;
    bottom?: string;
  };
  /**
   * Left-side padding on leading elements
   */
  leadingPaddingLeft?: SpacerProps["spacing"];
  /**
   * Right-side padding on leading elements
   */
  leadingPaddingRight?: SpacerProps["spacing"];
  /**
   * Left-side padding on trailing elements
   */
  trailingPaddingLeft?: SpacerProps["spacing"];
  /**
   * Right-side padding on trailing elements
   */
  trailingPaddingRight?: SpacerProps["spacing"];
  /*
   * Error message to be displayed
   */
  errorMessage?: string;
  /**
   * specifies whether or not an input field should have autocomplete enabled.
   */
  autocomplete?: boolean;
}

const Input = (props: InputProps) => {
  const {
    min,
    max,
    type,
    step,
    name,
    label,
    value,
    title,
    subText,
    pattern,
    disabled,
    readOnly,
    required,
    allowCopy,
    minLength,
    maxLength,
    placeholder,
    onlyAllowValidInput,
    onChange,
    onClick,
    leading: leadingElement,
    leadingInline,
    trailing,
    trailingInline,
    hideIndicators,
    hintText,
    infoHintText,
    inputPadding,
    leadingPaddingLeft,
    leadingPaddingRight,
    trailingPaddingLeft,
    trailingPaddingRight,
    disablePadding,
    errorMessage,
    autocomplete,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [error, dispatchError] = useReducer(
    (state, action) => {
      const newState = { ...state, ...action };
      if (newState.firstInput && newState.firstBlur) {
        newState.message = newState.text;
      }
      return newState;
    },
    { message: "", text: "", firstInput: false, firstBlur: false }
  );
  const inputId = useGenerateComponentID();
  const inputEl = useRef<HTMLInputElement>();

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onlyAllowValidInput && !e.target.validity.valid) {
        return;
      }
      if (disabled) {
        return;
      }
      if (readOnly) {
        return;
      }

      onChange(e);
    },
    [disabled, onChange, onlyAllowValidInput, readOnly]
  );

  const handleOnClick = (e) => onClick && onClick(e);

  useEffect(() => {
    dispatchError({ text: inputEl?.current?.validationMessage });
  }, [value]);

  const typeOverride = useMemo(() => {
    if (type === "password" && showPassword) {
      return "text";
    }

    return type;
  }, [type, showPassword]);

  const renderLabelText = useMemo(
    () =>
      label ? (
        <S.Label
          htmlFor={inputId}
          tw="flex text-utility-md font-medium text-black"
        >
          {label}
          {infoHintText ? (
            <>
              <Spacer isVertical={false} spacing="xxSmall" />
              <InfoTooltip content={infoHintText} size="xSmall" tw="text-sm" />
            </>
          ) : null}
        </S.Label>
      ) : null,
    [infoHintText, inputId, label]
  );
  const renderSubText = useMemo(
    () => (subText ? <S.SubText>{subText}</S.SubText> : null),
    [subText]
  );
  const renderCornerHint = useMemo(() => {
    const optional =
      !hideIndicators && !required && !readOnly && !disabled && "Optional";
    const text = hintText || optional;

    if (text) {
      return <S.CornerHintText>{text}</S.CornerHintText>;
    }
    return null;
  }, [disabled, hideIndicators, hintText, readOnly, required]);

  const renderErrorText = useMemo(
    () =>
      error.message || errorMessage ? (
        <S.ErrorText>{error.message || errorMessage}</S.ErrorText>
      ) : null,
    [error.message, errorMessage]
  );

  const shouldAutoComplete = useMemo(
    () => (autocomplete ? "on" : "new-password"),
    [autocomplete]
  );

  const trailingElement = useMemo(() => {
    const renderPasswordToggle = () => {
      const handlePasswordToggle = () => {
        setShowPassword(!showPassword);
      };
      const ButtonIcon = showPassword ? S.VisibilityOnIcon : S.VisibilityOnIcon;

      return (
        <S.TrailingIconContainer onClick={handlePasswordToggle}>
          <S.TrailingIcon>
            <ButtonIcon />
          </S.TrailingIcon>
        </S.TrailingIconContainer>
      );
    };

    const renderErrorIcon = () =>
      !hideIndicators && error.message ? (
        <S.ErrorIcon aria-hidden="true" />
      ) : null;

    const renderCopy = () => {
      return (
        <S.TrailingIconContainer>
          <S.TrailingIcon>
            <ContentCopy copyData={value?.toString()} />
          </S.TrailingIcon>
        </S.TrailingIconContainer>
      );
    };

    if (type === "password") {
      return renderPasswordToggle();
    }

    if (error.message) {
      return renderErrorIcon();
    }

    if (!trailing && allowCopy) {
      return renderCopy();
    }
    return trailing;
  }, [
    allowCopy,
    error.message,
    hideIndicators,
    showPassword,
    trailing,
    type,
    value,
  ]);

  const renderLeading = useMemo(() => {
    const renderLeadingOutline = (el) => (
      <S.LeadingOutlineContainer className="outline">
        {!disablePadding ? (
          <Spacer isVertical={false} spacing={leadingPaddingLeft} />
        ) : null}
        {el}
        {!disablePadding ? (
          <Spacer isVertical={false} spacing={leadingPaddingRight} />
        ) : null}
      </S.LeadingOutlineContainer>
    );
    const renderLeadingInline = (el) => (
      <S.LeadingInlineContainer error={!!error.message}>
        <S.LeadingInline>
          {!disablePadding ? (
            <Spacer isVertical={false} spacing={leadingPaddingLeft} />
          ) : null}
          {el}
          {!disablePadding ? (
            <Spacer isVertical={false} spacing={leadingPaddingRight} />
          ) : null}
        </S.LeadingInline>
      </S.LeadingInlineContainer>
    );
    if (leadingElement) {
      return leadingInline
        ? renderLeadingInline(leadingElement)
        : renderLeadingOutline(leadingElement);
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadingElement, error.message, leadingInline]);

  const renderTrailing = useMemo(() => {
    const renderTrailingOutline = (el) => (
      <S.TrailingOutlineContainer className="outline">
        {!disablePadding ? (
          <Spacer isVertical={false} spacing={trailingPaddingLeft} />
        ) : null}
        {el}
        {!disablePadding ? (
          <Spacer isVertical={false} spacing={trailingPaddingRight} />
        ) : null}
      </S.TrailingOutlineContainer>
    );
    const renderTrailingInline = (el) => (
      <S.TrailingInlineContainer error={!!error.message}>
        <S.TrailingInline>
          {!disablePadding ? (
            <Spacer isVertical={false} spacing={trailingPaddingLeft} />
          ) : null}
          {el}
          {!disablePadding ? (
            <Spacer isVertical={false} spacing={trailingPaddingRight} />
          ) : null}
        </S.TrailingInline>
      </S.TrailingInlineContainer>
    );

    if (trailingElement) {
      return trailingInline
        ? renderTrailingInline(trailingElement)
        : renderTrailingOutline(trailingElement);
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trailingElement, trailingInline, error.message]);

  return (
    <>
      {renderLabelText && (
        <S.LabelContainer>
          {renderLabelText}
          {renderCornerHint}
        </S.LabelContainer>
      )}
      <S.InputContainer $error={!!error.message}>
        {renderLeading}
        <S.InputElement
          type={typeOverride}
          id={inputId}
          name={name}
          min={min}
          max={max}
          step={step}
          value={value}
          title={title}
          ref={inputEl}
          pattern={pattern}
          disabled={disabled}
          readOnly={readOnly}
          required={required}
          placeholder={placeholder}
          onChange={handleOnChange}
          onClick={handleOnClick}
          minLength={minLength}
          maxLength={maxLength}
          onBlur={() => dispatchError({ firstBlur: error.firstInput })}
          onInput={() => dispatchError({ firstInput: true })}
          $padding={inputPadding}
          $isLeadingElement={!!leadingElement}
          $isTrailingElement={!!trailingElement}
          $leadingInline={leadingInline}
          $trailingInline={trailingInline}
          $error={error.message}
          autoComplete={shouldAutoComplete}
        />
        {renderTrailing}
      </S.InputContainer>
      {renderSubText}
      {renderErrorText}
    </>
  );
};

Input.defaultProps = {
  min: undefined,
  max: undefined,
  step: undefined,
  name: undefined,
  label: "",
  type: "text",
  pattern: null,
  required: false,
  disabled: false,
  hideIndicators: false,
  hintText: "",
  infoHintText: "",
  subText: "",
  title: undefined,
  onChange: undefined,
  onClick: undefined,
  placeholder: undefined,
  readOnly: false,
  onlyAllowValidInput: false,
  allowCopy: false,
  minLength: undefined,
  maxLength: undefined,
  leading: null,
  leadingInline: false,
  trailing: null,
  trailingInline: false,
  disablePadding: false,
  inputPadding: {},
  leadingPaddingLeft: "small",
  leadingPaddingRight: "small",
  trailingPaddingLeft: "small",
  trailingPaddingRight: "small",
  autocomplete: true,
};

export default Input;
