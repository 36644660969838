import React from "react";
import { IconProps } from "./Icons.type";

const DinersClub: React.FC<IconProps> = ({ className, fill = "#000000" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="48px"
      height="48px"
      className={className}
    >
      <path
        fill="#0079be"
        d="M27.605,40.637C36.699,40.68,45,33.22,45,24.144C45,14.22,36.699,7.36,27.605,7.363h-7.827	C10.575,7.36,3,14.222,3,24.144c0,9.078,7.575,16.536,16.778,16.492H27.605z"
      />
      <path
        fill="#fff"
        d="M19.815,8.738C11.406,8.741,4.591,15.557,4.589,23.97c0.002,8.412,6.817,15.227,15.226,15.23	c8.412-0.003,15.228-6.819,15.229-15.23C35.043,15.557,28.227,8.741,19.815,8.738z M10.164,23.97L10.164,23.97	c0.008-4.111,2.575-7.617,6.196-9.01v18.018C12.739,31.586,10.172,28.082,10.164,23.97z M23.27,32.982L23.27,32.982V14.96	c3.622,1.39,6.193,4.897,6.2,9.01C29.463,28.085,26.891,31.59,23.27,32.982z"
      />
      <linearGradient
        id="KknhlWBW17Od_nHxRMHnTa"
        x1="6.434"
        x2="35.441"
        y1="6.522"
        y2="35.529"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#199ae0" />
        <stop offset="1" stopColor="#0782d8" />
      </linearGradient>
      <path
        fill="url(#KknhlWBW17Od_nHxRMHnTa)"
        d="M27.605,41C36.699,41.044,45,33.421,45,24.148C45,14.006,36.699,6.997,27.605,7h-7.827	C10.575,6.997,3,14.008,3,24.148C3,33.423,10.575,41.044,19.778,41H27.605z"
      />
      <linearGradient
        id="KknhlWBW17Od_nHxRMHnTb"
        x1="7.191"
        x2="39.285"
        y1="11.192"
        y2="43.286"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset=".242" stopColor="#f2f2f2" />
        <stop offset="1" stopColor="#ccc" />
      </linearGradient>
      <path
        fill="url(#KknhlWBW17Od_nHxRMHnTb)"
        d="M19.999,9C11.715,9.003,5.002,15.716,5,24.001C5.002,32.285,11.715,38.997,19.999,39	c8.286-0.003,15-6.715,15.001-14.999C34.999,15.716,28.285,9.003,19.999,9z M10.491,24.001L10.491,24.001	c0.008-4.049,2.537-7.501,6.103-8.873v17.744C13.028,31.501,10.499,28.05,10.491,24.001z M23.401,32.876L23.401,32.876V15.127	c3.568,1.369,6.101,4.823,6.107,8.874C29.502,28.053,26.969,31.505,23.401,32.876z"
      />
    </svg>
  );
};

export default DinersClub;
