import { useApolloClient } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useCurrentUserQuery } from "features/users";
import { useLogoutUserMutation } from "features/logout";
import { createPath, RouteName } from "enums/routes";

const useAuth = () => {
  const client = useApolloClient();
  const history = useHistory();
  const { loading, data, refetch } = useCurrentUserQuery();
  const [logoutUserMutation] = useLogoutUserMutation();

  const auth = {
    loading,
    isLoggedIn: Boolean(data?.currentUser),
    currentUser: data?.currentUser,
		refetch,
  };

  const logout = async () => {
    if (auth.isLoggedIn) await logoutUserMutation();
    client.resetStore();
    history.push(createPath(RouteName.login));
  };

  return {
    auth,
    logout,
  };
};

export default useAuth;
