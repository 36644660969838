import { Google, Facebook } from "components/Icons";
import React from "react";

export interface AuthProviderDataType {
  id: string;
  name: string;
  title: string;
  logo: React.ReactElement;
  infoLink: string;
  text: string;
}

export const authProviderData: AuthProviderDataType[] = [
  {
    id: "GOOGLE",
    name: "Google",
    title: "Google",
    logo: <Google className="w-8 h-10" />,
    infoLink: "https://developers.google.com/identity/protocols/oauth2",
    text: "Let members authenticate with Google Authentication.",
  },
  {
    id: "FACEBOOK",
    name: "Facebook",
    title: "Facebook",
    logo: <Facebook className="w-8 h-10" />,
    infoLink:
      "https://developers.facebook.com/docs/facebook-login/web/accesstokens",
    text: "Let members authenticate with Facebook Authentication.",
  },
];
