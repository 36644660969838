const springDefaults = {
  friction: 80, // Setting this will increase/decrease wobble
  mass: 2,
  clamp: false,
};

const highTension = {
  tension: 1200,
  ...springDefaults,
};

const mediumTension = {
  tension: 700,
  ...springDefaults,
};

const lowTension = {
  tension: 100,
  ...springDefaults,
};

export const springConfigs = {
  highTension,
  mediumTension,
  lowTension,
};
